import React, { useState, useEffect } from "react";
import faqData from "../../data/faq.json";
import "./index.css";
import BannerText from "../../components/banner/bannerText";
import Header from "../../wrappers/header";
import Footer from "../../wrappers/footer";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    setFaqs(faqData);
  }, []);

  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
      "content",
      "Sık Sorulan Sorular (SSS) sayfamıza hoş geldiniz! Burada, en çok merak edilen konuları ve sıkça karşılaştığımız soruları sizler için derledik."
    );
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content =
      "Sık Sorulan Sorular (SSS) sayfamıza hoş geldiniz! Burada, en çok merak edilen konuları ve sıkça karşılaştığımız soruları sizler için derledik.";
    document.head.appendChild(newMetaTag);
  }

  return (
    <div className=" d-flex justify-content-center flex-column">
      <Helmet>
        <title>SSS | Crewier</title>
        <link rel="canonical" href={`https://www.crewier.com/sss`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header />
      <BannerText pageTitle="SSS" />
      <div className="container-fluid d-flex justify-content-center">
        <h1 className="d-none">Sıkça Sorulan Sorular Crewier</h1>
        <div className="col-lg-11 col-12"> <Accordion flush>
          {faqs.map((faq, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>{faq.question}</Accordion.Header>
              <Accordion.Body>{faq.answer}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion></div>
       
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
