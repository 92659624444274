import React from "react";
import logo from "../../assets/logo/crewier_logo_w_01.svg";
import logo2 from "../../assets/logo/alibaba_logo.svg";

const Footer = () => {
  return (
    <>
      <div className="container-fluid footer-crewier">
        <div className="row mt-5 d-lg-flex d-md-flex justify-content-between">
          <div className="col-lg-5 col-md-7 col-12">
            <div className="d-lg-flex d-md-flex justify-content-between footer-bottom-div pb-3">
              <img src={logo}></img>
              <img className="mt-lg-0 mt-3" src={logo2}></img>
            </div>
            <p className="mt-3">
              Crewier, küresel ticaretin öncü platformu Alibaba.com'un
              Türkiye'deki resmi iş ortağı olarak, işletmelerin uluslararası
              arenada güçlü bir şekilde yer almasını sağlayan bir hizmet
              sağlayıcıdır. Şirketimiz, işletmelere Alibaba.com üzerinde en
              etkin şekilde yer almaları, ürünlerini dünya pazarlarına
              tanıtmaları ve global müşteri kitlesine ulaşmaları için gerekli
              stratejik desteği sunmaktadır.
            </p>
            <h5>
              <a href="tel:+903123144444">+90 312 314 44 44</a>
            </h5>
            <h5>
              {" "}
              <a href="mailto:crewier@crewier.com">crewier@crewier.com</a>
            </h5>
          </div>

          <div className="col-lg-4 col-md-4 col-12 d-lg-flex text-end">
            {" "}
            <div className="col-xl-6 col-lg-6 col-12 mt-lg-0 mt-4">
              <a href="/">
                <h3>Anasayfa</h3>
              </a>

              <a href="/hakkimizda">
                <h3>Hakkımızda</h3>
              </a>
              <a href="/hizmetlerimiz">
                <h3>Hizmetlerimiz</h3>
              </a>
              <a href="/alibaba">
                <h3>Alibaba.com</h3>
              </a>
            </div>
            <div className="col-xl-6 col-lg-6 col-12 ">
              <a href="/e-ticaret">
                <h3>E-Ticaret</h3>
              </a>
              <a href="/e-ihracat">
                <h3>E-İhracat</h3>
              </a>
              <a href="/blog">
                <h3>Blog</h3>
              </a>
              <a href="/iletisim">
                <h3>İletişim</h3>
              </a>
              <a href="/sss">
                <h3>SSS</h3>
              </a>
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-3">
          <p className="text-center">
            ©️ 2024 | Crewier. All rights reserved.{" "}
            <a className="copyright" target="_blank" href="https://osicrew.com">
              Designed by OsiCrew
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
