import React from "react";
import BannerText from "../../components/banner/bannerText";
import img2 from "../../assets/gallery/three_d_icon/img_launch.png";
import img3 from "../../assets/gallery/three_d_icon/img_target.png";
import "./index.css";
import Header from "../../wrappers/header";
import Footer from "../../wrappers/footer";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
      "content",
      "Alibaba.com'da satış yapmak ve üye olmak isteyen işletmelere stratejik çözümler sunuyoruz!"
    );
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content =
      "Alibaba.com'da satış yapmak ve üye olmak isteyen işletmelere stratejik çözümler sunuyoruz!";
    document.head.appendChild(newMetaTag);
  }
  return (
    <div className="page-about-us bg-about-us">
      <Helmet>
        <title>Hakkımızda | Crewier</title>
        <link rel="canonical" href={`https://www.crewier.com/hakkimizda`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header />
      <BannerText pageTitle="Hakkımızda" />
      <h1 className="d-none">Hakkımızda Crewier</h1>
      <div className="container-fluid pb-5">
        <div className=" row col-lg-12 mt-4 page-about-us-one">
          <div className="col-lg-6 col-md-6 col-12 position-relative">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div className="d-flex flex-column justify-content-end ">
                      <h3 className="card-title">
                        <b>Misyon</b>
                      </h3>
                      <p className="card-text">
                        Dijital çağın gerekliliklerine uygun olarak, ihracat
                        yapan işletmelerin dijital dönüşümünü desteklemek ve
                        global pazarda rekabet güçlerini artırmak.
                      </p>
                    </div>
                    <div>
                      <img
                        className="three_icon_corporate"
                        src={img2}
                        alt="crewier"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6  col-md-6 col-12 mt-lg-0 mt-md-0 mt-4 position-relative">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div className="d-flex flex-column justify-content-end">
                      <h3 className="card-title">
                        <b>Vizyon</b>
                      </h3>
                      <p className="card-text">
                        İşletmelerin, küçük veya büyük fark etmeksizin,
                        e-ihracat süreçlerinde başarılı olmalarını sağlamak ve
                        Alibaba.com ile tanışarak satışlarını artırmalarına
                        yardımcı olmak.
                      </p>
                    </div>
                    <div>
                      <img
                        className="three_icon_corporate"
                        src={img3}
                        alt="crewier"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 mt-4">
          <div className="col-xl-4 col-lg-12 col-12 ">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-start pb-5">
                  <div className="d-flex pb-5">
                    <div className="d-flex flex-column justify-content-end pb-5">
                      <p className="card-text">
                      Crewier Digital Pazarlama, gelişmekte olan dünyanın dijital dönüşümlerine yönelik çözümleri yurt içi ve yurt dışında sunmayı amaçlıyor. Reklam hizmetleri, Alibaba.com danışmanlık hizmetleri, profesyonel fotoğraf çekimleri, hukuki danışmanlık gibi pek çok farklı alanda faaliyet gösteren Crewier, yıllar içerisinde A’dan Z’ye her ihtiyacınızda yanınızda olacak şekilde vizyonunu geliştirdi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-lg-6 col-12 mt-xl-0 mt-lg-4 mt-4 position-relative">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-start">
                  <div className="d-flex">
                    <div className="d-flex flex-column justify-content-end ">
                      <p className="card-text">
                        Başarılı olmanın tek yolunun “müşteri memnuniyetinden”
                        geçtiğini biliyor ve tüm iş süreçlerimizi bu ilke
                        çerçevesinde yapılandırıyoruz. Dijitalleşen dünyanın
                        ihtiyaçlarını farkında olan, ürün ve sistemlerini tüm
                        dünyayı takip ederek geliştiren ve özelleştiren Crewier,
                        müşterilerini çağın gereklerine uygun otomasyon
                        sistemleriyle buluşturuyor.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6  col-lg-6 col-12 mt-xl-0 mt-lg-4 mt-4 position-relative">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-start">
                  <div className="d-flex">
                    <div className="d-flex flex-column justify-content-end ">
                      <p className="card-text">
                        Crewier, Alibaba.com üzerinden satış yapmak ve üye olmak
                        isteyen işletmeler için stratejik çözümler ve partnerlik
                        sunar. Dijitalleşen dünyada e-ihracatın önemi her geçen
                        gün artmakta ve Crewier bu trendi yakalayarak
                        işletmenizin global pazarda rekabet gücünü artırmayı
                        hedeflemektedir.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
