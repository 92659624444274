import React, { useState, useRef } from "react";
import "./index.css";
import BannerText from "../../components/banner/bannerText";
import Header from "../../wrappers/header";
import Footer from "../../wrappers/footer";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const isValidPhoneNumber = (phoneNumber) => {
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(phoneNumber);
  };
  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute("content", "Sorularınız, önerileriniz veya iş birliği fırsatları için bizimle iletişime geçmekten çekinmeyin! ");
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content = "Sorularınız, önerileriniz veya iş birliği fırsatları için bizimle iletişime geçmekten çekinmeyin! ";
    document.head.appendChild(newMetaTag);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fromEmail = form.current.elements.from.value;
    const phoneNumber = form.current.elements.tel.value;
  
    if (!isValidEmail(fromEmail)) {
      toast.error("Lütfen geçerli bir e-posta adresi giriniz.", { autoClose: 4500 });
      return;
    }
  
    if (!isValidPhoneNumber(phoneNumber)) {
      toast.error("Lütfen geçerli bir telefon numarası giriniz.", { autoClose: 4500 });
      return;
    }
    if (!isButtonDisabled) {
      setIsButtonDisabled(true);

      const encryptedText = CryptoJS.AES.encrypt(
        uuidv4(),
        process.env.REACT_APP_SSK
      ).toString();

      const FD = new FormData(form.current);
      FD.append("accessToken", encryptedText);

      const obj = {
        message: FD.get("message"),
        userInfo: "Firma Adı: " +FD.get("firmaName") + " Yetkili Adı: " + FD.get("userInfoName") + " " + FD.get("userInfoSurname"),
        from: FD.get("from"),
        subject: FD.get("subject"),
        tel: FD.get("tel"),
        ownerName: "osiwebmail@gmail.com",
        to: "crewier@crewier.com",
        host: "smtp.gmail.com",
        service: "gmail",
        accessToken: encryptedText,
      };
      try {
        const res = await axios.post("https://nodemailer.3wweb.org/", obj, {
          auth: {
            username: "OSICrew_Node_Mailler",
            password: "1786oSi?173",
          },
        });
        if (res) {
          setIsSuccess(true);
          toast.success("Mesajınız gönderilmiştir.", { autoClose: 4500 });
          // console.log(res);
        }
      } catch (error) {
        // console.log(error);
        // console.log(obj);
        toast.error("Mesajınız gönderilememiştir.", { autoClose: 4500 });
      }

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    }
  };
  return (
    <><Helmet>
    <title>İletişim | Crewier</title>
    <link rel="canonical" href={`https://www.crewier.com/iletisim`} />
    <link rel="icon" href="/favicon.ico" />
  </Helmet>
  <Header />
  <ToastContainer/>
  <BannerText pageTitle="İletişim" /> <div className="container-fluid ">
       
      <div className="row col-lg-12 mt-4 pb-5">
        <h1 className="d-none">İletişim Crewier</h1>
        <div className="col-lg-5 col-12 mt-lg-0 mt-4">
          <div className="cardSlider h-100">
            <div className="card card-blur w-100">
              <div className="card-body d-flex flex-column justify-content-center">
                <form ref={form} onSubmit={handleSubmit}>
                <div className="col-12 mt-3">
                      <input
                        className="w-100"
                        type="text"
                        name="firmaName"
                        placeholder="Firma Adı"
                      ></input>
                    </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="col-5">
                      <input
                        className="w-100"
                        type="text"
                        name="userInfoName"
                        placeholder="Yetkili Ad"
                      ></input>
                    </div>
                    <div className="col-5">
                    <input
                        className="w-100"
                        type="text"
                        name="userInfoSurname"
                        placeholder="Soyad"
                      ></input>
                    </div>
                  </div>
                    <div className="col-12 mt-3">
                      <input
                        className="w-100"
                        type="text"
                        name="tel"
                        placeholder="Telefon"
                      ></input>
                    </div>
                    <div className="col-12 mt-3">
                      <input
                        className="w-100"
                        type="text"
                        name="from"
                        placeholder="E-Posta Adresi"
                      ></input>
                    </div>
                  
                  <div className="col-12 mt-3"> <input
                        className="w-100"
                        type="text"
                        name="subject"
                        placeholder="Konu"
                      ></input></div>
                  <div className="col-12 mt-4">
                    <textarea
                      className="w-100"
                      rows="6"
                      name="message"
                      placeholder="Mesaj"
                    ></textarea>
                    <button
                      className="btn-crewier mt-3"
                      id="send_btn"
                      disabled={isButtonDisabled}
                    >
                      Gönder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-12 mt-lg-0 mt-4">
          <div className="cardSlider h-100 ">
            <div className="card card-blur w-100">
              <div className="card-body">
                <div className="row col-12 mt-4">
                  <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                    <h6>Adres</h6>
                    <h6>
                      Söğütözü Mahallesi Söğütözü Caddesi Koç İkiz Kuleleri B
                      Blok Kat: 9, 06520 Çankaya
                    </h6>
                  </div>
                  <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                    <h6>İletişim</h6>
                    <h6>
                      <a href="tel:+903123144444">+90 312 314 44 44</a> <br />
                      <br />
                      <a href="mailto:crewier@crewier.com">
                        crewier@crewier.com
                      </a>
                    </h6>
                  </div>
                </div>
                <div >
                  <iframe
                    className="map-main"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.2836407842165!2d32.80120827671018!3d39.9126682715252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d348b24d0d5bd3%3A0x10c842d2b3be1d6!2sKo%C3%A7%20Kuleleri!5e0!3m2!1str!2str!4v1722522216086!5m2!1str!2str"
                    allowFullScreen
                    title="map"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div></>
   
  );
};

export default Contact;
