import React, { useState, useEffect } from "react";
import blogData from "../../data/blog.json";
import "./index.css";
import BannerText from "../../components/banner/bannerText";
import Layout from "../../layout";
import { removeTurkishChars } from "../../utils/urlUtils";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(blogData);
  }, []);

  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
      "content",
      "Yeni konular keşfetmek, başarı hikayeleri okumak veya Alibaba hakkında derinlemesine bilgi edinmek için bizi takip edin!"
    );
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content =
      "Yeni konular keşfetmek, başarı hikayeleri okumak veya Alibaba hakkında derinlemesine bilgi edinmek için bizi takip edin!";
    document.head.appendChild(newMetaTag);
  }
  return (
    <Layout>
      <Helmet>
        <title>Blog | Crewier</title>
        <link rel="canonical" href={`https://www.crewier.com/blog`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <BannerText pageTitle="Blog" />
      <div className="container-fluid mt-4 blog-page  bg-1f1f1f pb-5">
        <h1 className="d-none">Blog Crewier</h1>
        <div className="row col-lg-12 mt-4 ">
          {blogs.map((blog, index) => (
            <div
              className="col-xl-4 col-md-6 col-12 mt-4 title-main"
              key={index}
            >
              <a
                href={`/blog-detay/${removeTurkishChars(blog.title)
                  .replace(/\s+/g, "-")
                  .toLowerCase()}`}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <div className="cardSlider">
                  <div className="card card-blur w-100" style={{background:"#1f1f1f"}}>
                    <div className="card-body d-flex flex-column justify-content-between overflow-hidden">
                      <div className="content d-flex flex-column">
                        <img
                         
                          src={blog.img}
                          alt="crewier"
                        ></img>
                      </div>
                      <div className="content-three"></div>
                      <h3 className="card-title content-two  pt-3 m-0  bg-1f1f1f">
                        {blog.title}
                      </h3>
                      <div className="answer-main">
                        <h6 className="card-title ">
                        {ReactHtmlParser(blog.info.slice(0, 70)+"..")} 
                        </h6>
                        <button className="btn-crewier mt-4">
                          Daha Fazla
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
