import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import { FreeMode, Pagination, Mousewheel } from "swiper/modules";
import cardData from "../../data/cardSwiper.json";

export default function CardSwiper() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards(cardData);
  }, []);

  const formatTitle = (title) => {
    const words = title.split(" ");
    if (words.length > 1) {
      const lastWord = words.pop();
      const restOfTitle = words.join(" ");
      return (
        <>
          {restOfTitle} <strong>{lastWord}</strong>
        </>
      );
    }
    return title;
  };
  return (
    <div className="swiper-card-main h-100">
      <Swiper
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
        }}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        mousewheel={{
          releaseOnEdges: true,
        }}
        modules={[FreeMode, Pagination, Mousewheel]}
        className="mySwiper"
      >
        {cards.map((card, index) => (
          <SwiperSlide className=" title-main" key={index}>
            <div className="cardSlider m-0 pb-5">
              <div className="card card-blur w-100 bg-other" >
                <div className="card-body ">
                  <div className="content d-flex flex-column justify-content-between h-100">
                    <div>
                      <img className="w-100 mt-5" src={card.img} alt={card.title} />
                    </div>
                    <div >
                      <h5
                        className="card-title mb-5"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(card.title)}
                      </h5>
                    </div>
                  </div>
               <a href="/hizmetlerimiz"><button className="btn-crewier">Daha Fazla</button></a>  
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
