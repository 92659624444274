import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import appData from "../../data/app.json";
import { headerSticky } from "../../utils/header";

const Header = ({ darkHeader }) => {
  const location = useLocation();
  const navItems = appData.header.menu.map((item) => ({
    ...item,
    classes: "dropdown-link",
  }));

  const [desktopMenu, setDesktopMenu] = useState(false);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [hideHeaderTimeout, setHideHeaderTimeout] = useState(null);

  const toggleDesktopMenu = (e) => {
    e.preventDefault();
    const newDesktopMenuState = !desktopMenu;
    setDesktopMenu(newDesktopMenuState);

    // Clear any existing timeout to hide header
    if (hideHeaderTimeout) {
      clearTimeout(hideHeaderTimeout);
      setHideHeaderTimeout(null);
    }

    if (newDesktopMenuState) {
      // Menu is being opened
      setHeaderVisible(true); // Ensure header is visible when menu is opened
    } else {
      // Menu is being closed
      setHeaderVisible(true); // Ensure header is immediately visible when menu is closed
      // Set a timeout to hide the header 5 seconds after closing the menu
      const timeoutId = setTimeout(() => {
        setHeaderVisible(false);
      }, 1000); // 5 seconds delay
      setHideHeaderTimeout(timeoutId);
    }

    const menuPopup = document.querySelector(".osi-menu-popup");
    const menuButton = document.querySelector(".osi-menu-btn");
  
    if (newDesktopMenuState) {
      // Opening the menu
      menuPopup.classList.add("menu--visible");
      menuPopup.classList.add("menu--open");
      setTimeout(() => {
        menuButton.parentNode.classList.remove("osi--notouch");
        menuPopup.classList.add("menu--ready");
      }, 500);
    } else {
      // Closing the menu
      menuPopup.classList.remove("menu--ready");
      setTimeout(() => {
        menuPopup.classList.remove("menu--open");
      }, 300);
      menuButton.parentNode.classList.remove("osi--notouch");
    }
  };

  useEffect(() => {
    headerSticky();
  }, []);

  return (
    <header className={darkHeader ? "osi-header" : "osi-header header--white"} >
      <div className="header--builder">
        <div className="container">
          <div className="row">
            <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3 align-self-center">
              <div className="osi-logo-image">
                <Link to="/">
                  <img
                    src={appData.header.logo.image}
                    alt={appData.header.logo.alt}
                  />
                  <img
                    className="logo--white"
                    src={appData.header.logo.image_white}
                    alt={appData.header.logo.alt}
                  />
                </Link>
              </div>
            </div>
            <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-6 align-self-center text-center">
              <a
                href="#"
                aria-label="crewier-1"
                className={
                  desktopMenu ? "osi-menu-btn btn--active" : "osi-menu-btn"
                }
                onClick={toggleDesktopMenu}
              >
                <span />
              </a>
              <div className="osi-menu-popup text-start" style={{ display: headerVisible ? 'block' : 'none' }}>
                <div className="osi-menu-overlay" />
                <div className="osi-menu-overlay-after" />
                <div className="osi-menu-container osi--noscroll">
                  <div className="container osimenucontainer">
                    <div className="osi-menu">
                      <ul className="osi-menu-nav">
                        {navItems.map((item, key) => (
                          <li
                            key={`header-nav-item-${key}`}
                            className={`${item.classes} ${
                              location.pathname === item.link ? "active" : ""
                            }`}
                          >
                            <Link
                              className="osi-lnk"
                              to={item.link}
                              data-hover={item.label}
                            >
                              {item.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3 align-self-center text-end">
              <div className="osi-logo-image">
                <Link to="/">
                  <img
                    src={appData.header.logo.image}
                    alt={appData.header.logo.alt}
                  />
                  <img
                    className="logo--white"
                    src={appData.header.logo.image_alibaba}
                    alt={appData.header.logo.alt}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
