import React from "react";
import styled from "styled-components";



const CardSlider = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-6 col-12 ">
          <div className="cardSlider">
            <div className="card card-blur">
              <div className="card-body">
                <h2 className="card-title"> E-İhracat ile İşini </h2>
                <h2 className="card-title" style={{fontSize:"2.5rem"}}><b>Dünyayla Buluştur.</b>  </h2>
                <h3 className="card-title"> Official Partner</h3>
                <p className="card-text">
                Crewier, Alibaba.com üzerinden satış yapmak ve üye olmak isteyen işletmeler için stratejik çözümler ve partnerlik sunar.
                </p>
             <a href="/hizmetlerimiz"><button className="btn-crewier">Daha Fazla</button></a>   
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSlider;
