import React from "react";
import img1 from "../../assets/gallery/three_d_icon/img_growth.png";
import img2 from "../../assets/gallery/three_d_icon/img_chart.png";
import img3 from "../../assets/gallery/three_d_icon/img_money_tree.png";
import CardSwiper from "./cardSwiper";
import CardSwiperTwo from "./cardSwiperTwo";
import img5 from "../../assets/gallery/img_main/_0021_check.png";

const CardGrid = () => {
  return (
    <>
      <div className="transition-bg-main">
        <div className=" transition-bg"></div>
      </div>
      <div className="container-fluid ">
        <div className="row col-lg-12 ">
          <div className="col-lg-4 col-12 mt-0 ">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <h3 className="card-title">Başarılı</h3>
                  <h3 className="card-title"> E-İhracat </h3>
                  <h3 className="card-title">İçin Stratejik </h3>

                  <h3 className="card-title">
                    <b>Ortağınız </b>
                  </h3>
                  <h3 className="card-title">
                    <b>Crewier</b>
                  </h3>
                  <p className="card-text">
                    Dijital dönüşümün öncüsü olarak, uzman danışmanlık
                    hizmetlerimizle işinizi ileriye taşıyoruz.
                  </p>
                  <a href="/e-ihracat">
                    <button className="btn-crewier">Daha Fazla</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-8 col-12 mt-lg-0 mt-4">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div className="d-flex flex-column justify-content-end">
                      <h3 className="card-title">Doğru </h3>
                      <h3 className="card-title">Partnerle </h3>
                      <h3 className="card-title">
                        <b>Artan </b>
                      </h3>
                      <h3 className="card-title">
                        <b>Satışlar</b>
                      </h3>
                      <p className="card-text">
                        Crewier ile, e-ticaret ve e-ihracatta başarıyı yakalamak
                        artık daha kolay.
                      </p>
                      <a href="/e-ticaret">
                        <button className="btn-crewier">Daha Fazla</button>
                      </a>
                    </div>
                    <div>
                      <img className="w-100" src={img1} alt="crewier"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-lg-5 mt-4 px-2">
          {" "}
          <CardSwiperTwo />{" "}
        </div>

        <div className="row mt-4 col-lg-12 ps-lg-3 ps-0">
          <div className="col-lg-8 col-12 mt-lg-0 ">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="col-lg-6">
                    <h3 className="card-title">
                      <b>Hizmetlerimiz</b>
                    </h3>
                    <p className="card-text">
                      Müşterilerimizin dijital dünyada öne çıkmalarını sağlamak
                      için her alanda uzman ekibimizle, işletmelerin dijital
                      ihtiyaçlarını karşılamayı hedefliyoruz. Siz de dijital
                      varlığınızı güçlendirmek ve işinizi bir üst seviyeye
                      taşımak için bizimle iletişime geçin.
                    </p>
                  </div>
                  <a href="/hizmetlerimiz">
                    <button className="btn-crewier mb-4 mt-4">
                      Daha Fazla
                    </button>
                  </a>
                  <CardSwiper />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12 d-flex justify-content-between align-items-center flex-column mt-lg-0 mt-4 gap-3">
            <div className="row col-12">
              <div className="cardSlider h-100 m-0">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end mt-lg-5">
                    <div className="d-flex">
                      <div className="d-flex flex-column justify-content-end mt-lg-5">
                        <h3 className="card-title">Alibaba.com ile </h3>
                        <h3 className="card-title">Küresel Pazarda </h3>
                        <h3 className="card-title">
                          <b>Yerinizi Alın </b>
                        </h3>

                        <p className="card-text">
                          Ürün ve fiyatlarınızın ötesinde, hedef kitlenize en
                          doğru kanallardan ulaşarak, etkili pazarlama ve
                          reklamcılık çalışmaları ile satışlarınızı artırıyoruz.
                        </p>
                        <a href="/iletisim">
                          <button className="btn-crewier">Daha Fazla</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row col-12">
              <div className="cardSlider h-100 m-0">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end mt-lg-5">
                    <div className="d-flex">
                      <div className="d-flex flex-column justify-content-end mt-lg-5">
                        <h3 className="card-title">Alibaba.com ile </h3>
                        <h3 className="card-title">Crewier </h3>
                        <h3 className="card-title">
                          <b>Partnerliği</b>
                        </h3>
                        <p className="card-text">
                          Crewier, Alibaba.com’un resmi iş ortağı olarak,
                          işletmelerin Alibaba.com üzerinden daha etkin ve
                          verimli bir şekilde satış yapmalarını sağlayan
                          stratejik danışmanlık hizmetleri sunar.
                        </p>
                        <a href="/alibaba">
                          <button className="btn-crewier">Daha Fazla</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div
          className="row
       mt-4 col-lg-12"
        >
          <div className="col-lg-6 col-12  ">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div className="col-lg-5 d-flex flex-column justify-content-end">
                      <h3 className="card-title">
                        <b>E-İhracat</b>
                      </h3>
                      <p className="card-text">
                        Alibaba.com, e-ihracat konusunda işletmelere çeşitli
                        avantajlar ve hizmetler sunarak, uluslararası ticaretin
                        kolaylaşmasını sağlar.
                      </p>
                      <a href="/e-ihracat">
                        <button className="btn-crewier">Daha Fazla</button>
                      </a>
                    </div>
                    <div className="col-lg-7">
                      <img className="w-100" src={img3} alt="crewier"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 mt-lg-0 mt-4">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div className="col-lg-5 d-flex flex-column justify-content-end">
                      <h3 className="card-title">
                        <b>E-Ticaret</b>
                      </h3>
                      <p className="card-text">
                        Alibaba.com, dünyanın en büyük B2B e-ticaret
                        platformlarından biridir ve işletmelere global
                        pazarlarda ticaret yapma imkânı sağlar.
                      </p>
                      <a href="/e-ticaret">
                        {" "}
                        <button className="btn-crewier">Daha Fazla</button>
                      </a>
                    </div>
                    <div className="col-lg-7">
                      <img className="w-100" src={img2} alt="crewier"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12  mt-lg-4  d-flex justify-content-center">
            <div className="col-12 mt-xl-0 mt-4 position-relative">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-xl-flex">
                      <div className="d-flex justify-content-center">
                        <img
                          className="three_icon_corporate-two"
                          src={img5}
                          alt="crewier"
                        ></img>
                      </div>
                      <div className="d-flex flex-column justify-content-end alibaba-inner-top">
                        <h3>
                          Verified Hesap <br />
                          <b>Nedir & Ne İşe Yarar?</b>{" "}
                        </h3>
                        <h5 className="card-text mt-lg-0 ">
                          <p>
                            Alibabanın sunduğu &uuml;st d&uuml;zey Verified
                            &Uuml;yelik Paketi, sadece bağımsız denetimden
                            ge&ccedil;en, y&uuml;ksek kaliteli &uuml;reticilerin
                            alabileceği bir &uuml;yeliktir.&nbsp;
                          </p>
                          <p>
                            Denetim raporunuz (SGS/TUV) ve firmanıza &ouml;zel
                            &ccedil;ekilen tanıtım filmi mini sitenize eklenerek
                            alıcıların sizleri daha iyi tanıması sağlanır.
                          </p>
                          <p>
                            6 ay boyunca ek bir paket satın almanıza gerek
                            kalmadan, sabit trafik ve marketing desteği alarak
                            daha fazla alıcıya ulaşma olanağı sağlar.
                            <br />
                            Sahip olduğunuz sertifikalar ve kabiliyetler, arama
                            sonu&ccedil;larında ve mini sitenizde listelenir.
                          </p>
                          <p>
                            Sahip olduğunuz Verified rozeti ile daha &ccedil;ok
                            dikkat &ccedil;eker ve diğer &uuml;reticilerden
                            farklılaşarak daha ayrıcalıklı hale gelirsiniz.
                          </p>
                          <p>
                            Alibaba.com&rsquo;daki &uuml;retici firmalar
                            havuzunda yer alarak b&uuml;y&uuml;k alıcılarla
                            tanışabilirsiniz.
                          </p>
                          <p>
                            Alıcılarınıza dair detayları g&ouml;rebilir,
                            sistemde a&ccedil;ılan ihalelerde &ouml;ncelik
                            sahibi olabilirsiniz.
                          </p>
                          <p>
                            Satıcı hesabınızı ziyaret eden t&uuml;m alıcıların
                            sekt&ouml;rel ve demografik verilerini
                            inceleyebilir, yapay zek&acirc; destekli
                            optimizasyon ara&ccedil;larıyla daha fazla rekabet
                            edebilirsiniz.
                          </p>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

export default CardGrid;
