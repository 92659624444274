import React, { useState, useEffect } from "react";
import exportData from "../../data/export.json";
import "./index.css";
import BannerText from "../../components/banner/bannerText";
import Header from "../../wrappers/header";
import Footer from "../../wrappers/footer";
import img from "../../assets/gallery/img_main/_0016_export_02.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import { FreeMode, Pagination, Mousewheel } from "swiper/modules";
import { Helmet } from "react-helmet";

const EExport = () => {
  const [exports, setExports] = useState([]);

  useEffect(() => {
    setExports(exportData);
  }, []);

  const formatTitle = (title) => {
    const words = title.split(" ");
    if (words.length > 1) {
      const lastWord = words.pop();
      const restOfTitle = words.join(" ");
      return (
        <>
          {restOfTitle} <strong>{lastWord}</strong>
        </>
      );
    }
    return title;
  };

  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
      "content",
      "E-ihracat, elektronik ticaret yoluyla bir ülkenin sınırları dışındaki müşterilere ürün veya hizmet satışı yapma sürecidir."
    );
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content =
      "E-ihracat, elektronik ticaret yoluyla bir ülkenin sınırları dışındaki müşterilere ürün veya hizmet satışı yapma sürecidir.";
    document.head.appendChild(newMetaTag);
  }

  return (
    <div className=" d-flex justify-content-center flex-column overflow-x-hidden">
      <Helmet>
        <title>E-İhracat | Crewier</title>
        <link rel="canonical" href={`https://www.crewier.com/e-ihracat`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header />
      <BannerText pageTitle="E-İhracat" />
      <div className="container-fluid col-lg-12 d-lg-flex align-items-end  bg-1f1f1f">
        <h1 className="d-none">E-İhracat Crewier</h1>
        <div className="col-lg-6 col-12">
          <div className="cardSlider h-100 ">
            <div className="card-blur w-100 card">
              <div className="card-body d-flex flex-column justify-content-end mt-5">
                <h4 className="card-title mt-5">
                  E-ihracat, elektronik ticaret (e-ticaret) yoluyla yapılan
                  uluslararası satış ve ticaret faaliyetlerini ifade eder.
                  Geleneksel ihracatın dijital versiyonu olarak düşünülebilir.
                  E-ihracat sayesinde, işletmeler internet üzerinden global
                  pazarlara ulaşarak ürün ve hizmetlerini dünya genelinde
                  müşterilere sunabilirler. Bu yöntem, işletmelere daha geniş
                  bir müşteri kitlesine ulaşma, maliyetleri düşürme ve ticaret
                  süreçlerini hızlandırma imkânı tanır.
                  <br />
                  Alibaba.com, e-ihracat konusunda işletmelere çeşitli
                  avantajlar ve hizmetler sunarak, uluslararası ticaretin
                  kolaylaşmasını sağlar.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12 d-flex justify-content-center  mt-lg-0 mt-4">
          <img
            className="p-xl-0 p-5 img-export-commerce"
            src={img}
            alt="crewier"
          ></img>
        </div>
      </div>
      <div className="container-fluid">
        {" "}
        <div className=" row  bg-1f1f1f">
          <div className="p-4">
            <Swiper
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1025: {
                  slidesPerView: 4,
                },
              }}
              spaceBetween={30}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              mousewheel={{
                releaseOnEdges: true,
              }}
              modules={[FreeMode, Pagination, Mousewheel]}
              className="mySwiper"
            >
              {exports.map((blog, index) => (
                <SwiperSlide key={index}>
                  <div className="cardSlider m-0 pb-5">
                    <div className="card card-blur w-100">
                      <div className="card-body d-flex flex-column justify-content-between ">
                        <div className="mt-1">
                          <div className="col-12 d-flex justify-content-end">
                            <div className="col-7">
                              <img
                                className="w-100"
                                src={blog.img}
                                alt={blog.title}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          <h3
                            className="card-title"
                            style={{ width: "min-content" }}
                          >
                            {formatTitle(blog.title)}
                          </h3>
                          <h6 className="card-title">{blog.info} </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EExport;
