import React, { useState, useEffect } from "react";
import serviceData from "../../data/cardSwiper.json";
import "./index.css";
import BannerText from "../../components/banner/bannerText";
import Header from "../../wrappers/header";
import Footer from "../../wrappers/footer";
import { Helmet } from "react-helmet";
import img1 from "../../assets/gallery/img_main/crewier_post_slider_01.png";
import img2 from "../../assets/gallery/img_main/crewier_post_slider_02.png";
import img3 from "../../assets/gallery/img_main/crewier_post_slider_03.png";
import img4 from "../../assets/gallery/img_main/crewier_post_slider_04.png";
import img5 from "../../assets/gallery/img_main/crewier_post_slider_05.png";

import ReactHtmlParser from "react-html-parser";

const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    setServices(serviceData);
  }, []);

  const formatTitle = (title) => {
    const words = title.split(" ");
    if (words.length > 1) {
      const lastWord = words.pop();
      const restOfTitle = words.join(" ");
      return (
        <>
          {restOfTitle} <strong>{lastWord}</strong>
        </>
      );
    }
    return title;
  };
  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute("content", "");
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content = "";
    document.head.appendChild(newMetaTag);
  }

  return (
    <div className=" d-flex justify-content-center flex-column bg-services">
      <Helmet>
        <title>Hizmetlerimiz | Crewier</title>
        <link rel="canonical" href={`https://www.crewier.com/hizmetlerimiz`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header />
      <BannerText pageTitle="Hizmetlerimiz" />
      <div className="container-fluid d-flex justify content-center align-items-center flex-column mb-5">
        {" "}
        <div className="row col-lg-12 d-lg-flex d-md-flex">
          <div className="col-lg-5 col-md-5 col-12 mt-lg-0 mt-md-0 mt-4">
            <div className="h-100">
              <div className="cardSlider h-100 m-0">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    {services.length > 0 && (
                      <>
                        <img
                          style={{ width: "fit-content" }}
                          src={services[0].img}
                          alt={services[0].title}
                        ></img>
                        <h3
                          className="card-title mt-5"
                          style={{ width: "min-content" }}
                        >
                          {formatTitle(services[0].title)}
                        </h3>
                        <p className="card-text">{services[0].info}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-12 buse mt-lg-0 mt-md-0 mt-4">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card ">
                <div className="card-body d-flex justify-content-end">
                  <div className="buse-main d-flex gap-4">
                    {" "}
                    <img src={img1} alt="Image 1"></img>
                    <img src={img2} alt="Image 2"></img>
                    <img src={img3} alt="Image 3"></img>
                    <img src={img4} alt="Image 4"></img>
                    <img src={img5} alt="Image 4"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 d-lg-flex mt-4 d-md-flex">
          <div className="col-lg-7 col-md-5 col-12 ">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {services.length > 0 && (
                    <>
                      <img
                        style={{ width: "fit-content" }}
                        src={services[2].img}
                        alt={services[2].title}
                      ></img>
                      <h3
                        className="card-title mt-5"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(services[2].title)}
                      </h3>
                      <p className="card-text">{services[2].info}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-7 mt-lg-0 mt-md-0 mt-4">
            <div className="cardSlider h-100 m-0 ">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {services.length > 0 && (
                    <>
                      <img
                        style={{ width: "fit-content" }}
                        src={services[1].img}
                        alt={services[1].title}
                      ></img>
                      <h3
                        className="card-title mt-5"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(services[1].title)}
                      </h3>
                       <p className="card-text"> {ReactHtmlParser(services[1].info)}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 mt-4 d-lg-flex d-md-flex flex-md-wrap">
          <div className="col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {services.length > 0 && (
                    <>
                      <div className="col-12 d-flex justify-content-lg-end">
                        <div className="col-7">
                          {" "}
                          <img
                            className="services-img-five"
                            src={services[3].img}
                            alt={services[3].title}
                          ></img>
                        </div>
                      </div>{" "}
                      <h3
                        className="card-title mt-lg-0 mt-5"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(services[3].title)}
                      </h3>
                      <p className="card-text">{services[3].info}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-12  mt-lg-0 mt-md-0 mt-4">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {services.length > 0 && (
                    <>
                      <div className="col-12 d-flex justify-content-lg-end">
                        <div className="col-7 d-flex justify-content-lg-end">
                          {" "}
                          <img
                            src={services[4].img}
                            alt={services[4].title}
                          ></img>
                        </div>
                      </div>
                      <h3
                        className="card-title mt-lg-0 mt-5"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(services[4].title)}
                      </h3>
                      <p className="card-text">{services[4].info}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-12 col-md-12 col-12  mt-xl-0 mt-4">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {services.length > 0 && (
                    <>
                      <div className="col-12 d-flex justify-content-lg-end justify-content-md-end">
                        <div className="col-7 d-flex justify-content-lg-end justify-content-md-end">
                          {" "}
                          <img
                          className="services-img-five"
                            src={services[5].img}
                            alt={services[5].title}
                          ></img>
                        </div>
                      </div>

                      <h3
                        className="card-title mt-lg-0 mt-5"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(services[5].title)}
                      </h3>
                      <p className="card-text">{services[5].info}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 d-lg-flex mt-4 d-md-flex">
          <div className="col-lg-5 col-md-6 col-12 ">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {services.length > 0 && (
                    <>
                      <img
                        style={{ width: "fit-content" }}
                        src={services[6].img}
                        alt={services[6].title}
                      ></img>
                      <h3
                        className="card-title mt-5"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(services[6].title)}
                      </h3>
                      <p className="card-text">{services[6].info}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-6 mt-lg-0 mt-md-0 mt-4">
            <div className="cardSlider h-100 m-0 ">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {services.length > 0 && (
                    <>
                      <img
                        style={{ width: "fit-content" }}
                        src={services[7].img}
                        alt={services[7].title}
                      ></img>
                      <h3
                        className="card-title mt-5"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(services[7].title)}
                      </h3>
                      <p className="card-text">
                        {ReactHtmlParser(services[7].info)}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Services;
