import React from "react";
import Layout from "../../layout";
import img2 from "../../assets/gallery/img_main/_0016_headphones.png";
import img3 from "../../assets/gallery/img_main/_0017_list.png";
import img4 from "../../assets/gallery/img_main/_0018_premium_quality.png";
import img5 from "../../assets/gallery/img_main/_0021_check.png";
import { Helmet } from "react-helmet";
import "./index.css";

const Alibaba = () => {
  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
      "content",
      "Alibaba, 1999 yılında Çin'de Jack Ma tarafından kurulan bir e-ticaret ve teknoloji şirketidir."
    );
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content =
      "Alibaba, 1999 yılında Çin'de Jack Ma tarafından kurulan bir e-ticaret ve teknoloji şirketidir.";
    document.head.appendChild(newMetaTag);
  }
  return (
    <Layout>
      <Helmet>
        <title>Alibaba | Crewier</title>
        <link rel="canonical" href={`https://www.crewier.com/alibaba`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="page-alibaba bg-alibaba pb-5">
        <h1 className="d-none">Alibaba Crewier</h1>
        <div className="container-fluid d-flex justify-content-center  flex-column mb-5">
          <div className="w-100 text-center alibaba-logo-div">
            <img src="/logo/alibaba_logo.svg" alt="alibaba" />
          </div>
          <div className="row col-lg-12 mt-4 ">
            <div className="col-lg-6 col-md-6 col-12  ">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-flex">
                      <div className="d-flex flex-column justify-content-end ">
                        <h3 className="card-text ">
                          Alibaba.com
                          <br /> <b>Nedir & Ne İşe Yarar?</b>
                        </h3>
                        <h4 className="card-text">
                        Alibaba.com, dünyanın önde gelen B2B (business-to-business) e-ticaret platformlarından biridir. 1999 yılında Jack Ma tarafından kurulan Alibaba.com, küresel alıcılar ile tedarikçileri bir araya getirir. Platform, geniş ürün yelpazesi ile farklı sektörlerden işletmelere hizmet sunar ve işletmelerin uluslararası pazarda ticaret yapmalarını kolaylaştırır. Alibaba.com, güvenli ödeme sistemleri, lojistik çözümleri ve ticaret güvenliği sağlayarak, işletmelerin global çapta ticaret yapmasını destekler ve kişileri güvenilir şekilde bir araya getirir.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4 ">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-flex">
                      <div className="d-flex flex-column justify-content-end ">
                        <h3 className="card-text ">
                          Alibaba.com & Crewier <br /> <b>Partnerliği</b>
                        </h3>
                        <h4 className="card-text">
                          Crewier, Alibaba.com’un resmi iş ortağı olarak,
                          işletmelerin Alibaba.com üzerinden daha etkin ve
                          verimli bir şekilde satış yapmalarını sağlayan
                          stratejik danışmanlık hizmetleri sunar.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row col-lg-12  mt-4 d-flex justify-content-center">
            <div className="col-xl-4 col-lg-6 col-12 position-lg-relative">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-xl-flex ">
                      <div className="d-flex justify-content-center">
                        <img className="three_icon_corporate" src={img2}></img>
                      </div>
                      <div className="d-flex flex-column justify-content-end ">
                        <h3 className="card-text mt-lg-0 mt-4">
                          İşletmelerin takibini kusursuz bir şekilde sağlayarak,
                          Alibaba.com platformunun doğru şekilde
                          kullanıldığından emin olur.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-12 mt-lg-0 mt-4 ">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-between align-items-center">
                    <img className="w-75" src={img3}></img>

                    <h3 className="card-text mt-lg-0 mt-4">
                      Üyelik ve ek paket hizmetleri tanıtımı sağlar.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-12 mt-xl-0 mt-4 position-relative">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-xl-flex">
                      <div className="d-flex justify-content-center">
                        <img
                          className="three_icon_corporate"
                          src={img4}
                          alt="crewier"
                        ></img>
                      </div>
                      <div className="d-flex flex-column justify-content-end ">
                        <h3 className="card-text mt-lg-0 mt-4">
                          Platforma üye olan işletmelerin satışlarını
                          artırmaları için tüm dijital desteği sağlar.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row col-lg-12  mt-4 d-flex justify-content-center">
            <div className="col-12 mt-xl-0 mt-4 position-relative">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-xl-flex">
                      <div className="d-flex justify-content-center">
                        <img
                          className="three_icon_corporate-two"
                          src={img5}
                          alt="crewier"
                        ></img>
                      </div>
                      <div className="d-flex flex-column justify-content-end alibaba-inner-top">
                        <h3>
                          Verified Hesap <br />
                          <b>Nedir & Ne İşe Yarar?</b>{" "}
                        </h3>
                        <h4 className="card-text mt-lg-0 ">
                          <p>
                            Alibabanın sunduğu &uuml;st d&uuml;zey Verified
                            &Uuml;yelik Paketi, sadece bağımsız denetimden
                            ge&ccedil;en, y&uuml;ksek kaliteli &uuml;reticilerin
                            alabileceği bir &uuml;yeliktir.&nbsp;
                          </p>
                          <p>
                            Denetim raporunuz (SGS/TUV) ve firmanıza &ouml;zel
                            &ccedil;ekilen tanıtım filmi mini sitenize eklenerek
                            alıcıların sizleri daha iyi tanıması sağlanır.
                          </p>
                          <p>
                            6 ay boyunca ek bir paket satın almanıza gerek
                            kalmadan, sabit trafik ve marketing desteği alarak
                            daha fazla alıcıya ulaşma olanağı sağlar.
                            <br />
                            Sahip olduğunuz sertifikalar ve kabiliyetler, arama
                            sonu&ccedil;larında ve mini sitenizde listelenir.
                          </p>
                          <p>
                            Sahip olduğunuz Verified rozeti ile daha &ccedil;ok
                            dikkat &ccedil;eker ve diğer &uuml;reticilerden
                            farklılaşarak daha ayrıcalıklı hale gelirsiniz.
                          </p>
                          <p>
                            Alibaba.com&rsquo;daki &uuml;retici firmalar
                            havuzunda yer alarak b&uuml;y&uuml;k alıcılarla
                            tanışabilirsiniz.
                          </p>
                          <p>
                            Alıcılarınıza dair detayları g&ouml;rebilir,
                            sistemde a&ccedil;ılan ihalelerde &ouml;ncelik
                            sahibi olabilirsiniz.
                          </p>
                          <p>
                            Satıcı hesabınızı ziyaret eden t&uuml;m alıcıların
                            sekt&ouml;rel ve demografik verilerini
                            inceleyebilir, yapay zek&acirc; destekli
                            optimizasyon ara&ccedil;larıyla daha fazla rekabet
                            edebilirsiniz.
                          </p>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Alibaba;
