import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import img1 from "../../assets/gallery/img_main/crewier_slider_04.png";
import img2 from "../../assets/gallery/img_main/crewier_slider_03.png";
import img3 from "../../assets/gallery/img_main/crewier_slider_02.png";
import img4 from "../../assets/gallery/img_main/crewier_slider_01.png";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

export default function CardSwiperTwo() {
  return (
    <div className='swiper-card-main-two'>
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        loop={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={img1} alt="Slide 1" />
          <div className="overlay-text"><h3>Yavaş İlerleyen<br/>Global Pazar Arayışınıza <br/> <b>Hız Katıyoruz</b></h3></div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={img2} alt="Slide 2" />
          <div className="overlay-text"><h3><b>365 Gün, 7/24 Açık</b><br/>Fuar Alanında <br/>Yerinizi Alın</h3></div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={img3} alt="Slide 3" />
          <div className="overlay-text"><h3>Düşüncede Kalmasın<br/>Satışa Dönüşsün <br/> Diyenlerin Platformu <br/><b>Alibaba.com</b></h3></div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={img4} alt="Slide 4" />
          <div className="overlay-text-end"><h3>Dünyanın Her Yerinden<br/>Satıcılarla Alıcıları<b> Buluşturuyoruz</b></h3></div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
