import React, { useState, useEffect } from "react";
import faqData from "../../../data/faq.json";
import "./index.css";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    setFaqs(faqData);
  }, []);
  return (
    <div className="container-fluid mt-4 faq-page">
      <div className="row">
        <div className="col-xl-6 col-12 mt-lg-0 ">
          <div className="p-3">
            <h2 className="card-title"><b>SSS</b> </h2>
            <h3 className="card-text">
              Hizmetlerimiz, üyelik süreçlerimiz, teknik destek ve diğer konular
              hakkında merak ettiğiniz her şeyi ve Daha Fazlanı hemen öğrenin.
            </h3>
            <a target="_blank" href="/sss"> <button className="btn-crewier">Daha Fazla</button></a>
           
          </div>
        </div>
      </div>
      <div className="row col-lg-12 d-flex justify-content-center pb-4">
        {faqs.slice(0, 3).map((faq, index) => (
          <div className="col-lg-4 col-md-6 col-12 mt-4 title-main" key={index}>
            <div className="cardSlider">
              <div className="card card-blur w-100">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="">
                    <h3 className="card-title content p-0 m-0">
                      {faq.question}
                    </h3>
                    <div className="answer-main">
                      <h5 className="card-title ">{faq.answer}</h5>
                    <a target="_blank" href="/sss"><button className="btn-crewier mt-4">Daha Fazla</button></a>  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
